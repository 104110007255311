import Head from "next/head";
import React from "react";
import { sectionsNavSettingsFallback } from "../section-fallbacks";
import Site from "@/components/Site";
import Section from "../Section";
import { createNestedTreeForLocale } from "@/helpers/createNastedPages";
import { useRouter } from "next/router";
import { Locale } from "types";

function CustomErrorPage({ errorPages, siteData, pagesMeta }) {
  const {
    query: { slugs },
  } = useRouter();

  const site = siteData;
  const colors = siteData.color.colors;
  const errorPagesData = errorPages;
  const locales = site.locale;
  const localesKeys = Object.keys(locales) as Locale[];
  const defaultLocale =
    localesKeys.find((locale) => locales[locale].default_locale) || "en";
  const currentLocale = slugs.includes("ar")
    ? "ar"
    : slugs.includes("en")
    ? "en"
    : defaultLocale;

  const customErrorPage = errorPagesData.find(
    (page) => page.language === currentLocale
  );

  const universalSections = site.universal_sections;

  const isOldHomeHeader =
    !universalSections?.header_expanded[currentLocale]?.section_settings ||
    !!universalSections?.header_expanded[currentLocale]?.section_settings
      ?.background;

  const currentGlobalHeader = universalSections?.header_expanded[currentLocale];

  const universalSectionsWithFallback = {
    ...universalSections,
    header_expanded: {
      ...universalSections?.header_expanded,
      [currentLocale]: {
        ...currentGlobalHeader,
        section_settings: isOldHomeHeader
          ? {
              ...sectionsNavSettingsFallback[
                currentGlobalHeader?.section_design
              ]?.section_settings,
              ...(!customErrorPage.home_page &&
              sectionsNavSettingsFallback[currentGlobalHeader?.section_design]
                ?.section_settings.style.isTransparent &&
              sectionsNavSettingsFallback[currentGlobalHeader?.section_design]
                .section_settings.colors.navLinksColor === "#000000"
                ? {
                    style: {
                      ...sectionsNavSettingsFallback[
                        currentGlobalHeader?.section_design
                      ].section_settings.styles,
                      isTransparent: false,
                    },
                  }
                : {}),
            }
          : currentGlobalHeader.section_settings,
      },
    },
  };

  const pages_Meta = pagesMeta;
  const pages = createNestedTreeForLocale(pages_Meta, currentLocale);
  const languages = Object.keys(locales)
    .map((language) => ({
      iso: language,
      active: currentLocale === language,
      link: locales[language].default_locale ? "/" : `/${language}`,
    }))
    .filter((locale) => locales[locale.iso].active);
  const menuItems = createNestedTreeForLocale(
    site.menu_items || [],
    currentLocale
  );

  const universalData = {
    locales,
    languages,
    pages,
    menuItems: menuItems || pages,
    logo: site.logo,
    favicon: site.favicon || null,
  };

  return (
    <div>
      <Head>
        <title>{customErrorPage?.name}</title>
        <style
          dangerouslySetInnerHTML={{
            __html: `.siteBody {
						--theme-color-primary: ${colors[0]};
            --wt-btn-primary-bg-color:${colors[0]};
						--theme-color-secondary: ${colors[1]};
						--theme-color-gradient: ${colors[3]};
					}`,
          }}
        />
      </Head>
      <Site
        websiteId={site.website_id}
        page={customErrorPage}
        pages={pages_Meta}
        universalSections={universalSectionsWithFallback}
        universalData={universalData}
        currentLocale={customErrorPage?.language}
        defaultLocale={site.defaultLocale}
        animation={site.animation}
        theme={site.theme}
      >
        {customErrorPage?.page_sections.map((section) => {
          return (
            <Section
              key={section.id}
              id={section.id}
              universal={{
                ...universalData,
                pageName: customErrorPage.name,
              }}
              design={section.section_design}
              values={section.section_value}
              animation={site.theme ? site.theme.animation : site.animation}
              sectionSettings={section.section_settings}
              addExtraPaddingForGlobalNavbar={false}
            />
          );
        })}
      </Site>
    </div>
  );
}

export default CustomErrorPage;
